import auth from "@/middleware/auth";
import hasRoles from "@/middleware/hasRoles";
import { ROLES } from "@/statements/roles";

export default {
  path: "",
  meta: {
    middlewares: [auth, hasRoles],
    roles: [ROLES.PHARMACIST],
  },
  children: [
    {
      path: "prescriptions",
      name: "PrescriptionList",
      component: () =>
        import(
          /* webpackChunkName: "prescription-list" */ "@/views/Pharmacist/PrescriptionList.vue"
        ),
    },
    {
      path: "prescription/:id",
      name: "CreatePrescription",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "create-prescription" */ "@/views/Pharmacist/CreatePrescription.vue"
        ),
    },
    {
      path: "deliveries",
      name: "DeliveriesList",
      component: () =>
        import(
          /* webpackChunkName: "deliveries-list" */ "@/views/Pharmacist/DeliveriesList.vue"
        ),
    },
  ],
};
