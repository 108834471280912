import { createStore } from "vuex";

import app from "./modules/app";
import auth from "./modules/auth";
import billingData from "./modules/billingData";
import cart from "./modules/cart";
import doctors from "./modules/doctors";
import examinations from "./modules/examinations";
import facilities from "./modules/facilities";
import language from "./modules/language";
import languages from "./modules/languages";
import medicalCategories from "./modules/medicalCategories";
import message from "./modules/message";
import modals from "./modules/modals";
import patients from "./modules/patients";
import referrals from "./modules/referrals";
import statuses from "./modules/statuses";
import user from "./modules/user";
import loader from "./modules/loader";
import results from "./modules/results";
import invoices from "./modules/invoices";
import referralGroups from "./modules/referralGroups ";
import features from "./modules/features";
import breadcrumbs from "./modules/breadcrumbs";
import createdinvoices from "./modules/createdinvoices";
import raports from "./modules/raports";
import adminPatients from "./modules/adminPatients";
import testsToDescribe from "./modules/testsToDescribe";
import diseases from "./modules/diseases";
import docLanguages from "./modules/docLanguages";
import doctorServices from "./modules/doctorServices";
import appointmentHours from "./modules/appointmentHours";
import call from "./modules/call";
import adminDiscountCode from "./modules/adminDiscountCode";
import clinicServices from "./modules/clinicServices";
import dietaryPlans from "./modules/dietaryPlans";
import prescriptions from "./modules/prescriptions";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    language,
    languages,
    user,
    medicalCategories,
    auth,
    patients,
    facilities,
    modals,
    examinations,
    referrals,
    cart,
    doctors,
    statuses,
    message,
    billingData,
    loader,
    results,
    invoices,
    referralGroups,
    features,
    breadcrumbs,
    createdinvoices,
    raports,
    adminPatients,
    testsToDescribe,
    diseases,
    docLanguages,
    doctorServices,
    appointmentHours,
    call,
    adminDiscountCode,
    clinicServices,
    dietaryPlans,
    prescriptions,
  },
  strict: process.env.NODE_ENV !== "production",
});
