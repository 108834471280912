import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    prescriptionId: null,
    storedFile: null,
    file: null,
    selectedMedicationId: null,
    selectedDosageId: null,
    errors: {},
  },
  getters: {
    getFile: (state) => state.file,
    getStoredFile: (state) => state.storedFile,
    getSelectedMedicationId: (state) => state.selectedMedicationId,
    getSelectedDosageId: (state) => state.selectedDosageId,
    getErrors: (state) => state.errors,
  },
  mutations: {
    setPrescriptionId(state, prescriptionId) {
      state.prescriptionId = prescriptionId;
    },
    setStoredFile(state, storedFile) {
      state.storedFile = storedFile;
    },
    setFile(state, file) {
      state.file = file;
    },
    setSelectedMedicationId(state, selectedMedicationId) {
      state.selectedMedicationId = selectedMedicationId;
    },
    setSelectedDosageId(state, selectedDosageId) {
      state.selectedDosageId = selectedDosageId;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
  },
  actions: {
    async fetchPrescription({ commit }, id) {
      commit("setPrescriptionId", id);
      commit("dietaryPlans/setIsLoading", true, {
        root: true,
      });

      const { data } = await http.get(`pharmacist/prescriptions/${id}`);

      if (data.prescription?.medication) {
        commit("setSelectedMedicationId", data.prescription.medication.id);
        commit(
          "setSelectedDosageId",
          data.prescription.medication.details.dosage_id
        );
      }

      if (data.prescription) {
        commit("setStoredFile", data.prescription.file);
      }

      commit("dietaryPlans/setPatientData", data.patient, { root: true });
      commit("dietaryPlans/setSelectedPlan", data.selected_plan, {
        root: true,
      });
      commit("dietaryPlans/setDoctorDescription", data.doctor_description, {
        root: true,
      });
      commit("dietaryPlans/setTestResults", data.test_results, { root: true });
      commit("dietaryPlans/setHealthMetrics", data.health_metrics, {
        root: true,
      });
      commit("dietaryPlans/setGeneralSurvey", data.general_survey, {
        root: true,
      });
      commit("dietaryPlans/setWeightLossSurvey", data.weight_loss_survey, {
        root: true,
      });

      commit("dietaryPlans/setIsLoading", false, {
        root: true,
      });
    },
    async storePrescription({ state }, isDone) {
      let formData = new FormData();
      if (state.file) {
        formData.append("file", state.file ?? "");
      } else {
        formData.append("fileId", state.storedFile?.id ?? "");
      }
      formData.append("medicineId", state.selectedMedicationId ?? "");
      formData.append("dosageId", state.selectedDosageId ?? "");
      formData.append("isDone", isDone);

      return http.post(
        `pharmacist/prescriptions/${state.prescriptionId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  },
};
