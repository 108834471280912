import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

const getFreshAIPanelOptions = () => [
  {
    name: "tests_to_describe.summarize_for_patient_communication",
    order: 2,
    key: "summarize_for_patient_communication",
    color: "orange",
    active: false,
    enabled: true,
  },
  {
    name: "tests_to_describe.generate_health_risk_assessment",
    order: 3,
    key: "health_risk_assessment",
    color: "purple",
    active: false,
    enabled: true,
  },
  {
    name: "tests_to_describe.create_follow_up_plan",
    order: 4,
    key: "follow_up_plan",
    color: "light-blue-1",
    active: false,
    enabled: true,
  },
  {
    name: "tests_to_describe.prescribe_lifestyle_modifications",
    order: 5,
    key: "lifestyle_modifications",
    color: "mint",
    active: false,
    enabled: true,
  },
];

export default {
  namespaced: true,
  state: {
    referrals: [],
    AIPanelOptions: getFreshAIPanelOptions(),
    descriptionContent: "",
    isClassifiedForMedication: false,
    descriptionSections: [],
    descriptionSectionsInit: [],
    sectionColors: {
      profile: "yellow",
      results: "blue",
      health_risk_assessment: "purple",
      follow_up_plan: "light-blue-1",
      lifestyle_modifications: "mint",
      summarize_for_patient_communication: "orange",
    },
    pagination: {
      currentPage: 1,
      total: 1,
    },
    currentReferral: null,
    relatedReferral: null,
    quiz: null,
    referralExaminations: [],
    resultsToReview: [],
    resultsExpectedRange: [],
    maxSalaryRange: 1000,
    isLoading: false,
    isOpenAIPanel: false,
    isMovedAIPanel: false,
    desctiptionOptions: [],
    welcomeOption: 0,
    summaryOption: 0,
    isExpandedAddNewWelcome: false,
    isExpandedAddNewSummary: false,
    errors: [],
  },
  getters: {
    getReferrals: (state) => state.referrals,
    getDescriptionSections: (state) => state.descriptionSections,
    getPagination: (state) => state.pagination,
    getMaxSalaryRange: (state) => state.maxSalaryRange,
    getCurrentReferral: (state) => state.currentReferral,
    getRelatedReferral: (state) => state.relatedReferral,
    getQuiz: (state) => state.quiz,
    hasQuiz: (state) => state.quiz !== null,
    getReferralExaminations: (state) => state.referralExaminations,
    getResultsToReview: (state) => state.resultsToReview,
    getResultsExpectedRange: (state) => state.resultsExpectedRange,
    getDesctiptionOptionsById: (state) => (id) =>
      state.desctiptionOptions.find((option) => option.id === id),
    getWelcomeOptions: (state) =>
      state.desctiptionOptions.filter((option) => option.type === "welcome"),
    getSummaryOptions: (state) =>
      state.desctiptionOptions.filter((option) => option.type === "summary"),
    getSectionColor: (state) => (name) => {
      const color = state.sectionColors[name];

      return color ?? "gray";
    },
    isLoading: (state) => state.isLoading,
    isOpenAIPanel: (state) => state.isOpenAIPanel,
    isMovedAIPanel: (state) => state.isMovedAIPanel,
    getErrors: (state) => state.errors,
    getErrorsByType: (state) => (type) => state.errors[type],
    getIsClassifiedForMedication: (state) => state.isClassifiedForMedication,
  },
  mutations: {
    setIsClassifiedForMedication(state, isClassified) {
      state.isClassifiedForMedication = isClassified;
    },
    setReferrals(state, referrals) {
      state.referrals = referrals.data;
      state.pagination.currentPage = referrals.meta.current_page;
      state.pagination.total = referrals.meta.last_page;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setDescriptionContent(state, descriptionContent) {
      state.descriptionContent = descriptionContent;
    },
    setCurrentReferral(state, currentReferral) {
      state.currentReferral = currentReferral;
    },
    setRelatedReferral(state, relatedReferral) {
      state.relatedReferral = relatedReferral;
    },
    setQuiz(state, quiz) {
      state.quiz = quiz;
    },
    setReferralExaminations(state, referralExaminations) {
      state.referralExaminations = referralExaminations;
    },
    setResultsToReview(state, resultsToReview) {
      state.resultsToReview = resultsToReview;
    },
    setResultsExpectedRange(state, resultsExpectedRange) {
      state.resultsExpectedRange = resultsExpectedRange;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsOpenAIPanel(state, isOpenAIPanel) {
      state.isOpenAIPanel = isOpenAIPanel;
    },
    setIsMovedAIPanel(state, isMovedAIPanel) {
      state.isMovedAIPanel = isMovedAIPanel;
    },
    setMaxSalaryRange(state, maxSalaryRange) {
      state.maxSalaryRange = maxSalaryRange;
    },
    pushDesctiptionOptions(state, desctiptionOption) {
      state.desctiptionOptions = [
        ...state.desctiptionOptions,
        desctiptionOption,
      ];
    },
    setDesctiptionOptions(state, desctiptionOptions) {
      state.desctiptionOptions = desctiptionOptions;
    },
    setDescriptionSectionsInit(state, descriptionSections) {
      state.descriptionSectionsInit = descriptionSections;
    },
    setWelcomeOption(state, welcomeOption) {
      state.welcomeOption = welcomeOption;
    },
    setSummaryOption(state, summaryOption) {
      state.summaryOption = summaryOption;
    },
    setIsExpandedAddNewWelcome(state, isExpanded) {
      state.isExpandedAddNewWelcome = isExpanded;
    },
    setIsExpandedAddNewSummary(state, isExpanded) {
      state.isExpandedAddNewSummary = isExpanded;
    },
    setActiveAIPanelOption(state, { key, isActive, order }) {
      const option = state.AIPanelOptions.find((item) => item.key == key);
      if (option) {
        option.active = isActive;
        option.order = order ?? option.order;
      }
    },
    pushSection(state, section) {
      state.descriptionSections = [...state.descriptionSections, section].sort(
        (itemA, itemB) => {
          if (itemA.order > itemB.order) return 1;
          else if (itemA.order < itemB.order) return -1;

          return 0;
        }
      );
    },
    setSections(state, sections) {
      state.descriptionSections = sections.sort((itemA, itemB) => {
        if (itemA.order > itemB.order) return 1;
        else if (itemA.order < itemB.order) return -1;

        return 0;
      });
    },
    updateSection(state, { name, content }) {
      const index = state.descriptionSections.findIndex(
        (section) => section.name === name
      );

      state.descriptionSections[index].content = content;
    },
    removeSection(state, name) {
      state.descriptionSections = state.descriptionSections.filter(
        (item) => item.name != name
      );
    },
    updateAIPanelOptions(state, options) {
      state.AIPanelOptions = options;
    },
    updateAIPanelOptionsOrder(state) {
      state.AIPanelOptions = state.AIPanelOptions.sort(
        (itemA, itemB) => itemA.order - itemB.order
      ).map((item, index) => ({ ...item, order: index + 2 }));
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearDescriptions(state) {
      state.isOpenAIPanel = false;
      state.isMovedAIPanel = false;
      state.AIPanelOptions = getFreshAIPanelOptions();
      state.descriptionSections = [];
      state.descriptionSectionsInit = [];
    },
    clearReferrals(state) {
      state.referrals = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
  },
  actions: {
    addSection({ commit, getters }, { name, content, order }) {
      commit("pushSection", {
        color: getters.getSectionColor(name),
        name,
        content,
        order,
      });
    },
    removeSection({ state, commit }, name) {
      commit(
        "setSections",
        state.descriptionSections.filter((item) => item.name != name)
      );
    },
    updateSectionOrder({ state, commit }, orderedItems) {
      commit(
        "setSections",
        state.descriptionSections.map((section) => {
          const orderItem = orderedItems.find(
            (item) => item.key == section.name
          );
          return {
            ...section,
            order: orderItem ? orderItem.order : section.order,
          };
        })
      );
    },
    updateSections({ commit }, sectionsData) {
      sectionsData.forEach((section) => {
        commit("updateSection", section);
      });
    },
    updateDescriptionContent({ commit }, descriptionContent) {
      commit("setDescriptionContent", descriptionContent);
    },
    generateDescriptionContent({ commit, state }) {
      commit(
        "setDescriptionContent",
        state.descriptionSections.reduce((content, section) => {
          return `${content} <section-component name='${section.name}' key='${section.name}' color="${section.color}" class="mt-5" sectionsButtons="true" >${section.content}</section-component>`;
        }, "")
      );
    },

    async saveNewDescriptionOption({ commit }, option) {
      return http
        .post("/doctor/describe/description-options", option)
        .then(({ data }) => {
          commit("pushDesctiptionOptions", data.option);
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });

          return Promise.resolve(data.option);
        })
        .catch(({ response }) => {
          if (response && response.status === 422) {
            commit("setErrors", { [option.type]: response.data.errors });
          }

          throw new Error(response);
        });
    },
    async fetchReferrals(
      { commit, state },
      { time, date_range, filters, sortBy, query } = {
        time: null,
        date_range: null,
        filters: null,
        sortBy: null,
        query: null,
      }
    ) {
      commit("setIsLoading", true);
      const { data } = await http.get("/doctor/describe/referrals", {
        params: {
          page: state.pagination.currentPage,
          time,
          date_range,
          ...filters,
          ...sortBy,
          ...query,
        },
      });

      commit("setReferrals", data);
      commit("setIsLoading", false);
      commit("setMaxSalaryRange", data.maxSalaryRange);
    },
    async fetchReferral({ commit }, { id }) {
      commit("setIsLoading", true);

      const { data } = await http.get(`doctor/describe/referrals/${id}`);

      commit("setCurrentReferral", data.data.testToDescribe);
      commit("setReferralExaminations", data.data.examinations);
      commit("setResultsToReview", data.data.toReview);
      commit("setResultsExpectedRange", data.data.expectedRange);
      commit("setDescriptionSectionsInit", data.data.descriptionActiveSections);
      commit("setRelatedReferral", data.data.relatedReferral);
      commit("setQuiz", data.data.quiz);
      commit(
        "setIsClassifiedForMedication",
        data.data.testToDescribe.is_classified_for_medication
      );

      commit("setIsLoading", false);
    },
    async storeReferral({ state }, isDone) {
      return http.put(`doctor/describe/referrals/${state.currentReferral.id}`, {
        content: state.descriptionSections,
        is_classified_for_medication: state.isClassifiedForMedication,
        isDone,
      });
    },
    async fetchDesctiptionOption({ commit, getters, dispatch }) {
      const { data } = await http.get("doctor/describe/description-options");

      commit("setDesctiptionOptions", data);
      commit("setWelcomeOption", getters.getWelcomeOptions[0]?.id);
      commit("setSummaryOption", getters.getSummaryOptions[0]?.id);
      commit("pushSection", {
        color: "gray",
        name: "greeting",
        content: getters.getWelcomeOptions[0]?.content,
        order: 1,
      });
      commit("pushSection", {
        color: "gray",
        name: "summary",
        content: getters.getSummaryOptions[0]?.content,
        order: 9,
      });

      dispatch("generateDescriptionContent");
    },
    async describeByAi({ getters }, { type }) {
      const { data } = await http.post(
        "doctor/describe/ai",
        {
          descriptionId: getters.getCurrentReferral.id,
          type,
        },
        { timeout: 600000 }
      );

      return data;
    },
    async getNextTestId(_, { currentId, isPrevious }) {
      const { data } = await http.get(
        "doctor/describe/next-test/" + currentId,
        {
          params: {
            is_previous: isPrevious,
          },
        }
      );
      return data;
    },
    setUpInitDescriptionSections({ commit, state, getters }) {
      if (state.descriptionSectionsInit?.length > 0) {
        commit(
          "setSections",
          state.descriptionSectionsInit.map((item) => ({
            color: getters.getSectionColor(item.name),
            name: item.name,
            content: item.content,
            order: item.order,
          }))
        );

        state.descriptionSectionsInit.forEach((item) => {
          commit("setActiveAIPanelOption", {
            key: item.name,
            isActive: true,
            order: item.order,
          });
        });

        commit("updateAIPanelOptionsOrder");
      }
    },
  },
};
